import { template as template_171e56f925ce4c759ca4b1f48a4261a8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_171e56f925ce4c759ca4b1f48a4261a8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
