import { template as template_8f34d44dc42747d4ab314a870861927a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_8f34d44dc42747d4ab314a870861927a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
