import { template as template_6e403c41cf39472887f1f62f14b68727 } from "@ember/template-compiler";
const FKText = template_6e403c41cf39472887f1f62f14b68727(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
