import { template as template_b280b2d13c7f4ee69cd0505f0ca57443 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b280b2d13c7f4ee69cd0505f0ca57443(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
