import { template as template_006ee065a06149fea922eb53e8fd1339 } from "@ember/template-compiler";
const FKLabel = template_006ee065a06149fea922eb53e8fd1339(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
